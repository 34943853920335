/*
 * @Description: 
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 13:49:13
 * @LastEditors: yujiangping
 * @LastEditTime: 2025-03-03 18:40:00
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/common.less"
import {IMG_PUBLIC_PATH,AIPRB_IMG_PATH,IMG_PUBLIC_PATH_QM} from './utils/constants.js'
// import RedbearChats from 'https://img.redbearai.com/lingqi/dist/redbear-chats.umd.js'
// import { RedbearChatWindow } from 'redbear-chats'

const app = createApp(App)


app.config.globalProperties.$IMG_PUBLIC_PATH = IMG_PUBLIC_PATH
app.config.globalProperties.$IMG_PUBLIC_PATH_QM = IMG_PUBLIC_PATH_QM
app.config.globalProperties.$AIPRB_IMG_PATH = AIPRB_IMG_PATH
// app.use(window.RedbearChats)
// console.log('RedbearChats:',window.RedbearChatsJas);
// console.log('RedbearChatWindow:', window.RedbearChats.RedbearChatWindow);
app.use(store)
app.use(router)
// app.use(window.RedbearChatsJas)
// app.use(window.RedbearChats)
// if(window.RedbearChats){
//     app.component('RedbearChatWindow', window.RedbearChats.RedbearChatWindow)
// }else{
//     console.warn('RedbearChatWindow component is not available.');
// }
app.mount('#app')
// RedbearChats.app.mount('#app');
// console.log(window.RedbearChats)
// window.RedbearChats.app.mount('#app');
// console.log(RedbearChats)
