<!--
 * @Description: 价格方案
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-11 13:49:13
 * @LastEditors: yujiangping
 * @LastEditTime: 2025-03-10 18:05:08
-->
<template>
  <div class="home">
      <header-nav />
      <div class="home-1">
          <div class="container flex-row justify-between">
              <div class="flex-col">
                  <h1 class="-ml-110">通过<span class="primary-font-color pl-10">红熊AI</span></h1>
                  <h1 class="-mt-38 -ml-110">释放企业生产力</h1>
                  <span class="mt-30">细分红熊AI多种产品能力，为不同企业级客户提供对应得Ai服务和解决方案</span>
                  <div class="flex-row justify-between tips">
                      <span>· 可快速转化</span>
                      <span>· 可快速验证</span>
                      <span>· 可快速交付</span>
                    
                  </div>
              </div>
              <div class="flex-col">
                  <img :src="$IMG_PUBLIC_PATH + 'rb-illustration%402x.png'" class="rb-illustration" />
              </div>
          </div>
      </div>
      <div class="container flex-row justify-between">
          <div class="flex-col item-box -mt-73">
              <span class="font-main">新一代人工智能客服</span>
              <span class="font-desc mt-15">私有化产品</span>
              <span class="mt-50"><span class="font-price">800000</span>/年</span>
              <div class="rb-button mt-30">
                  <span>开始使用</span>
              </div>
              <div class="flex-col mt-30 span-box">
                  <span>团队成员 无限制</span>
                  <span>支持业务应用 无限制</span>
                  <span>私有化知识库 无限制</span>
                  <span>API接入 支持</span>
                  <span>文件上传限额 无限制</span>
                  <span>文件处理优先级 标准</span>
                  <span>可接入呼叫中心 标准</span>
                  <span>日志历史记录 15天</span>
                  <span>Chat BI 支持</span>
              </div>
          </div>
          <div class="flex-col item-box -mt-73">
              <span class="font-main">新一代人工智能售前</span>
              <span class="font-desc mt-15">私有化产品</span>
              <span class="mt-50"><span class="font-price">980000元</span>/年</span>
              <div class="rb-button mt-30">
                  <span>开始使用</span>
              </div>
              <div class="flex-col mt-30 span-box">
                  <span>团队成员 无限制</span>
                  <span>构建支持应用程序 无限制</span>
                  <span>高速转化 无限制</span>
                  <span>API接入 支持</span>
                  <span>文件上传 无限制</span>
                  <span>CRM接入 支持</span>
                  <span>智能检测 优先</span>
                  <span>消息请求 无限</span>
                  <span>日志历史记录 无限</span>
                  <span>Chat BI 支持</span>
              </div>
          </div>
          <div class="flex-col item-box active -mt-73">
              <img :src="$IMG_PUBLIC_PATH + 'website/recommend%402x.png'" class="recommend"/>
              <span class="font-main">红熊AI多模态中台</span>
              <span class="font-desc mt-15">企业级私有化</span>
              <span class="mt-50"><span class="font-price">综合报价</span></span>
              <div class="rb-button active mt-30">
                  <span>开始使用</span>
              </div>
              <div class="flex-col mt-30 span-box">
                  <span>红熊产品应用 全部支持</span>
                  <span>企业级私有化知识库 全部支持</span>
                  <span>企业级私有化大模型 全部支持</span>
                  <span>企业级多模态文本 全部支持</span>
                  <span>企业级工作流编排 全部支持</span>
                  <span>企业级智能体 全部支持</span>
                  <span>企业级图像处理 全部支持</span>
                  <span>企业级数字人 全部支持</span>
                  <span>企业级业务产品接入 全部支持</span>
                  <span>企业级自定义工具 全部支持</span>
              </div>
          </div>
      </div>
      <div class="flex-col container privatized-box mt-60">
          <h2>红熊AI多模态能力私有化版</h2>
          <div class="flex-row justify-between">
              <span>一站式集成红熊所有Ai能力与产品，让企业一瞬间具备 AI 能力</span>
              <div class="flex-col">
                  <span class="flex-row items-center justify-start"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">包含红熊AI所有企业版所有功能</span></span>
                  <span class="flex-row items-center justify-start mt-40"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">私有化打模型打造可靠的商业级平台</span></span>
                  
              </div>
              <div class="flex-col">
                  <span class="flex-row items-center justify-start"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">快速转化、验证、交付</span></span>
                  <span class="flex-row items-center justify-start mt-40"><img :src="$IMG_PUBLIC_PATH + 'website/icon-checked%402x.png'" class="icon-18"/><span class="ml-20">拥有多场景成功落地的行业经验和运营经验</span></span>
              </div>
          </div>
          <div class="flex-row justify-between items-start">
              <div class="flex-row items-center">
                 <button class="black-button">咨询报价</button>
              </div>
              <img :src="$IMG_PUBLIC_PATH + 'website/logo-shading%402x.png'" class="logo-shading-small"/>
          </div>
      </div>
      <div class="flex-col container justify-center mt-80">
          <span class="fs-36">客户说</span>
          <span class="fs-20-thin mt-30">红熊AI涵盖多个领域，收获众多好评</span>
          <div class="flex-row justify-between mt-60">
              <div class="flex-col item-box">
                  <span class="font-desc h-120">“红熊AI不仅为我们提供了强大的AI工具，还帮助我们实现了业务流程的自动化，使我们能够更快地响应客户需求，显著提升了客户满意度。通过AI分析和自动化工具，我们能够更好地管理和优化业务流程，降低运营成本，提高整体运营效率。”</span>
                  <div class="flex-row justify-between mt-40">
                      <img :src="$IMG_PUBLIC_PATH + 'website/shading-1%402x.png'" class="icon-56-36"/>
                      <span>— — 浙江移动</span>
                  </div>
              </div>
              <div class="flex-col item-box">
                  <span class="font-desc h-120">“红熊AI平台使我们的运营效率得到了质的飞跃。通过AI分析和自动化工具，我们可以更好地管理和优化业务流程，降低运营成本，提高了整体运营效率。”</span>
                  <div class="flex-row justify-between mt-40">
                      <img :src="$IMG_PUBLIC_PATH + 'website/shading-1%402x.png'" class="icon-56-36"/>
                      <span>— — 慢慢跑运营总监</span>
                  </div>
              </div>
              <div class="flex-col item-box">
                  <span class="font-desc h-120">“在投资领域，数据的准确性和分析的深度至关重要。红熊AI的强大分析工具帮助我们更好地理解市场动态，制定更精确的投资策略，提升了客户满意度和投资回报率。” </span>
                  <div class="flex-row justify-between mt-40">
                      <img :src="$IMG_PUBLIC_PATH + 'website/shading-1%402x.png'" class="icon-56-36"/>
                      <span>— — 睿趣商务咨询CEO</span>
                  </div>
              </div>
          </div>
      </div>
      <div class="flex-row container justify-between mt-80">
          <div class="question-item-box flex-row items-start justify-between">
              <span class="fs-30">常见问题</span>
              <img :src="$IMG_PUBLIC_PATH + 'website/arrow-question%402x.png'" class="icon-28 mt-10" />
              <img :src="$IMG_PUBLIC_PATH + 'website/question%402x.png'" class="icon-question" />
          </div>
          <div class="h-400-box flex-col">
              <div class="flex-col item-box-q items-start">
                  <span class="font-main">除了提供 AI 开发工具平台，红熊 AI 还提供哪些服务？</span>
                  <span class="font-desc mt-10">红熊 AI 还提供包括 AI 应用落地培训，AI 工作流定制，模型微调在内的多种服务，满足用户的多样化需求</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">红熊 AI 相较于市面上常见的 Chat 类工具有什么区别？</span>
                  <span class="font-desc mt-10">红熊 AI 专注于提供定制化的企业级解决方案，包括专业业务工作流构建培训，以适应特定行业的垂直需求。</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">如何快速开发一个 AI项目？</span>
                  <span class="font-desc mt-10">红熊 AI 通过 Agent 工作流模式，用户根据自己的业务需求快速匹配和完成项目管理</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">红熊 AI 是否支持定制化开发？</span>
                  <span class="font-desc mt-10">红熊 AI 提供定制化开发服务，以适应不同垂直化场景的特定需求。</span>
              </div>
              <div class="flex-col item-box-q items-start">
                  <span class="font-main mt-30">红熊 AI 是如何计费的？</span>
                  <span class="font-desc mt-10">提供灵活的服务方式，包括私有化部署和 SAAS（软件即服务）模式，计费标准根据服务内容和项目规模定制化设定。</span>
              </div>
          </div>
      </div>
      <regbox-footer class="mt-60"/>
      <airb-footer class="mt-60"/>
  </div>
</template>

<script>
// @ is an alias to /src
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'
export default {
  name: 'HomeView',
  components: {
    headerNav,airbFooter,regboxFooter
  }
}
</script>
<style lang="less" scoped>
.rb-illustration{
    width: 442px;
    height: 470px;
}
</style>
