<!--
 * @Description: 解决方案
 * @Version: 0.0.1
 * @Author: yujiangping
 * @Date: 2024-06-14 14:14:41
 * @LastEditors: yujiangping
 * @LastEditTime: 2025-03-07 18:31:16
-->
<template>
    <div class="flex-col bg-white">
        <header-nav />
        <div class="container-full case-1">
            <div class="container flex-row  justify-between">
                <div class="flex-col w-480 justify-start items-start">
                    <span class="fs-48 mt-130">
                        红熊<span class="fc-primary">多模态</span>大模型
                    </span>
                    <span class="fs-18 mt-30 text-left fc-sub">红熊是以客户为中心、通过人工智能技术推动企业场景化的降本增效，赋能业绩增长</span>
                    <div class="mt-100 fs-18 flex-row fc-primary">
                        <span>·  聚焦核心</span>
                        <span class="ml-40">· 树立行标</span>
                    </div>
                </div>
                <div class="">
                    <img class="case-1-img" :src="$IMG_PUBLIC_PATH + 'website/case-1-bg%402x.png'"/>
                </div>
            </div>
        </div>
        <div class="container-full p-r bg-f5f7fc">
            <img :src="$IMG_PUBLIC_PATH + 'website/case-bg-1%402x.png'" class="case-2-bg"/>
            <div class="container flex-col items-center">
                <span class="mt-80 fs-36"><span class="fc-primary">丰富、成熟、专业</span>的解决方案</span>
                <span class="mt-20 fc-sub fs-20">红熊AI聚焦行业核心场景，深入打造行业标准化解决方案</span>
                <div class="mt-40 flex-col w-full">
                    <div class="flex-row justify-center">
                        <span class="case-tab-title" :class="i===solutionCurItem ? 'active' : ''" v-for="(item,i) in solutionTablist" :key="item.templateName" @click="solutionCurItem = i">{{item.title}}</span>
                    </div>
                    <!-- B2B电商 -->
                    <div class="w-full solution-bg mt-40" v-if="solutionCurItem === 0">
                        <div class="flex-row justify-between p-r">
                            <div class="flex-col text-left">
                                <span class="fs-24-fat">B2B电商领域<span class="fc-primary">数据驱动决策优化</span></span>
                                <img :src="$IMG_PUBLIC_PATH + 'website/icon-case-1%402x.png'" class="icon-36 mt-60" />
                                <span class="fc-main fs-18-fat mt-20">智能化未来构建</span>
                                <span class="fc-sub mt-10 fs-14 w-420">围绕图片、文本相关领域合作，一键式工作流生成，减少运营团队作业，增加广告投放效果，减少试错成本</span>
                                <span class="fc-main fs-18-fat mt-60">大模型文生图科技助力</span>
                                <span class="fc-sub mt-10 fs-14 w-420">B2B平台电商：商品主图、详情图片、详情文案、商品海报、商品投放图片生成</span>
                            </div>
                            <img class="solution-img-1" :src="$IMG_PUBLIC_PATH + 'website/solution-img%402x.png'"/>
                        </div>
                    </div>
                    <!-- 咨询 -->
                    <div class="w-full solution-bg solution-bg-1 mt-40" v-if="solutionCurItem === 1">
                        <div class="flex-col text-left">
                            <span class="fs-24-fat"><span class="fc-primary">Agent </span>智能体生成与撰写资产报告</span>
                            <span class="w-880 fc-sub fs-14 mt-20">借助红熊多模态大模型优秀的文本生成能力，解决传统的信息提取、报告、草稿生成、心系符合、文档翻译中的难题，提升顾问效率，为客户带来更满意的交付体验。</span>
                            <div class="flex-row w-full justify-between mt-40 p-r">
                                <div class="flex-col w-full">
                                    <span class="fs-44 fc-primary flex-row items-center">10<span class="fs-20-fat mt-10 ml-10">%</span></span>
                                    <span class="mt-10 fc-main fs-16-fat">整体员工效率提升</span>
                                    <div class="flex-row-wrap mt-70 w-420">
                                        <span class="fs-18-fat">· 文档分析</span>
                                        <span class="fs-18-fat ml-80">· 数据切分</span>
                                        <span class="fs-18-fat ml-80">· 快捷翻译</span>
                                        <br />
                                        <span class="fs-18-fat mt-20">· 信息提取</span>
                                        <span class="fs-18-fat ml-80 mt-20">· 报告生成</span>
                                    </div>
                                </div>
                                <img :src="$IMG_PUBLIC_PATH + 'website/s-img-2%402x.png'" class="s-img-2"/>
                            </div>
                        </div>
                    </div>
                    <!-- 传媒 -->
                    <div class="w-full solution-bg mt-40" v-if="solutionCurItem === 2">
                        <div class="flex-col text-left">
                            <span class="fs-24-fat"><span class="fc-primary">AI 智能助手</span>构建广告营销文案专家</span>
                            <span class="mt-20 fc-sub fs-14 w-880">AI智能助手通过精准受众分析和实时优化，实现多渠道个性化广告投放，并通过自动化生成创意文案，持续优化内容和提升广告效果。</span>
                            <div class="flex-row justify-between items-center mt-40 s-bg p-r">
                                <div class="flex-col">
                                    <div class="flex-col s-bg-1 text-left">
                                        <span class="fs-20-fat fc-primary">精准与个性化的广告投放</span>
                                        <div class="flex-row fs-16-fat mt-20">
                                            <span class="fc-main">· 受众分析</span>
                                            <span class="fc-main ml-40">· 行为预测</span>
                                            <span class="fc-main ml-40">· 实时优化</span>
                                            <span class="fc-main ml-40">· 多渠道适应性</span>
                                        </div>
                                    </div>
                                    <div class="flex-col s-bg-1 text-left mt-30">
                                        <span class="fs-20-fat fc-primary">高效与创新的文案创作</span>
                                        <div class="flex-row fs-16-fat mt-20">
                                            <span class="fc-main">· 自动化创作</span>
                                            <span class="fc-main ml-40">· 创意无限</span>
                                            <span class="fc-main ml-40">· 内容优化</span>
                                            <span class="fc-main ml-40">· 持续学习</span>
                                        </div>
                                    </div>
                                </div>
                                <img :src="$IMG_PUBLIC_PATH + 'website/s-img-3%402x.png'" class="s-img-3 mr-25" />
                            </div>
                        </div>
                    </div>
                    <!-- 旅游 -->
                    <div class="w-full solution-bg mt-40" v-if="solutionCurItem === 3">
                        <div class="flex-col text-left">
                            <span class="fs-24-fat"><span class="fc-primary">AI小助手 </span>助力旅游产业</span>
                            <span class="mt-20 fc-sub fs-14">通过AI技术和大数据分析，以“内容+交易”模式，提供针对热门旅游目的地的一站式信息服务和产品预订服务。为用户打造个性的旅游体验，以“内容获客”模式助理上架提升获客效率，推动旅游行业的创新发展。</span>
                            <div class="mt-40 flex-row text-left">
                                <div class="flex-col w-200 border-r-1 text-left">
                                    <span class="flex-row items-center fc-primary fs-44">80 <span class="fs-20-fat mt-10 ml-10">+</span></span>
                                    <span class="mt-10 fs-16-fat fc-main">服务性测试评分</span>
                                    <span class="mt-70 fs-16-fat fc-main">· AI旅游伙伴</span>
                                    <span class="mt-20 fs-16-fat fc-main">· 个性化旅游定制</span>
                                </div>
                                <img :src="$IMG_PUBLIC_PATH + 'website/s-img-4%402x.png'" class="s-img-4 ml-80" />
                            </div>
                        </div>
                    </div>
                    <!-- 心理咨询 -->
                    <div class="w-full solution-bg mt-40" v-if="solutionCurItem === 4">
                        <div class="flex-col text-left">
                            <span class="fs-24-fat"><span class="fc-primary">心理咨询</span>-感情与精神寄托的第三空间</span>
                            <span class="mt-20 fc-sub fs-14 w-880">深入挖掘海量心理咨询数据，并构建最新模型智能体能力框架，进行整体升级换代，与大模型协同配合，将综合复杂场景进行云上分流，共同实现更丰富、更贴心、更惊艳的多模态心理辅导智能化场景服务。</span>
                            <div class="mt-40 flex-row">
                                <div class="flex-col text-left">
                                    <span class="flex-row items-center fc-primary fs-44">13 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                    <span class="mt-10 fs-16-fat fc-main">付费转化率提升</span>
                                    <span class="mt-40 fs-16-fat fc-main">· 7000+次咨询</span>
                                    <span class="mt-20 fs-16-fat fc-main">· 30%响应提升</span>
                                    <span class="mt-20 fs-16-fat fc-main">· 95%识别率</span>
                                </div>
                                <div class="flex-col justify-center items-center s-img-box-1 ml-100 mt-20">
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-case-xinli-1%402x.png'" class="icon-36 " />
                                    <span class="fc-main fs-16-fat mt-40">无缝交互</span>
                                    <span class="fc-main fs-16-fat">有问必答</span>
                                </div>
                                <div class="flex-col justify-center items-center s-img-box-2 ml-30 mt-20">
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-case-xinli-2%402x.png'" class="icon-36 " />
                                    <span class="fc-main fs-16-fat mt-40">多重意图</span>
                                    <span class="fc-main fs-16-fat">瞬间感知</span>
                                </div>
                                <div class="flex-col justify-center items-center s-img-box-3 ml-30 mt-20">
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-case-xinli-3%402x.png'" class="icon-36 " />
                                    <span class="fc-main fs-16-fat mt-40">人设多元化</span>
                                    <span class="fc-main fs-16-fat">沉浸式体验</span>
                                </div>
                                <div class="flex-col justify-center items-center s-img-box-4 ml-30 mt-20">
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-case-xinli-4%402x.png'" class="icon-36 " />
                                    <span class="fc-main fs-16-fat mt-40">情感理解</span>
                                    <span class="fc-main fs-16-fat">温情对话</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 汽车 -->
                    <div class="w-full solution-bg mt-40 p-r" v-if="solutionCurItem === 5">
                        <img :src="$IMG_PUBLIC_PATH + 'website/aiCar%402x.png'" class="s-img-car" />
                        <div class="flex-col text-left p-r">
                            
                            <span class="fs-24-fat z-index-99"><span class="fc-primary">汽车售后</span>-AI服务助手</span>
                            <span class="mt-20 fc-sub fs-14 w-880">红熊大模型赋能维修助手，一盏售后咨询人员，车辆维修人员可以通过耳机+麦克+触屏的智能终端方式，实时与维修人员交互，提供维修原因/处置方案/预诊断结论等。</span>
                            <div class="mt-40 flex-row">
                                <div class="flex-col text-left">
                                    <span class="flex-row items-center fc-primary fs-44">85 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                    <span class="mt-10 fs-16-fat fc-main">提升售后准确性</span>
                                </div>
                                <div class="flex-col text-left s-img-box ml-100 mt-20">
                                   <span class="fs-28 fc-primary">01</span>
                                   <span class="mt-10 fc-main fs-18-fat">初诊</span>
                                   <span class="mt-10 fc-sub fs-14 line-h-24">· 初诊计划+概率排序</span>
                                    <span class="fc-sub fs-14 line-h-24">· 过程引导</span>
                                    <span class="fc-sub fs-14 line-h-24">· 实时描述+计划修正</span>
                                    <span class="fc-sub fs-14 line-h-24">· 初诊结论</span>
                                    <span class="fc-sub fs-14 line-h-24">（可能故障+概率排序）</span>
                                </div>
                                <div class="flex-col text-left s-img-box ml-30 mt-20">
                                   <span class="fs-28 fc-primary">02</span>
                                   <span class="mt-10 fc-main fs-18-fat">诊断</span>
                                   <span class="mt-10 fc-sub fs-14 line-h-24">· 诊断方案+概率排序</span>
                                    <span class="fc-sub fs-14 line-h-24">· 过程引导</span>
                                    <span class="fc-sub fs-14 line-h-24">· 实时描述+计划修正</span>
                                    <span class="fc-sub fs-14 line-h-24">· 排查结论</span>
                                    <span class="fc-sub fs-14 line-h-24">（故障定位）</span>
                                </div>
                                <div class="flex-col text-left s-img-box ml-30 mt-20">
                                    <span class="fs-28 fc-primary">03</span>
                                    <span class="mt-10 fc-main fs-18-fat">修理</span>
                                    <span class="mt-10 fc-sub fs-14 line-h-24">· 修理计划</span>
                                    <span class="fc-sub fs-14 line-h-24">· 过程引导</span>
                                    <span class="fc-sub fs-14 line-h-24">· 实时描述+计划修正</span>
                                </div>
                                <div class="flex-col text-left ml-30 mt-20">
                                    <div class="flex-row items-center s-img-box s-img-box-11 text-left">
                                        <div class="flex-col">
                                            <span class="fs-28 fc-primary">04</span>
                                            <span class="fc-main fs-18-fat">验收</span>
                                        </div>
                                        <div class="flex-col ml-20">
                                            <span class="fc-sub fs-14 line-h-24">· 验收测试计划</span>
                                            <span class="fc-sub fs-14 line-h-24">· 过程引导</span>
                                            <span class="fc-sub fs-14 line-h-24">· 实时描述</span>
                                        </div>
                                    </div>
                                     <div class="flex-row items-center s-img-box s-img-box-11 text-left mt-30">
                                        <div class="flex-col">
                                            <span class="fs-28 fc-primary">05</span>
                                            <span class="fc-main fs-18-fat">报告</span>
                                        </div>
                                        <div class="flex-col ml-20">
                                            <span class="fc-sub fs-14 line-h-24">过程记录自动</span>
                                            <span class="fc-sub fs-14 line-h-24">生成维修记录</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 企业招聘 -->
                    <div class="w-full solution-bg mt-40 s-bg-6 p-r" v-if="solutionCurItem === 6">
                        <img :src="$IMG_PUBLIC_PATH + 'website/img-person%402x.png'" class="img-person">
                        <div class="flex-col text-left">
                            <span class="fs-24-fat"><span class="fc-primary">企业招聘</span>-提效AI助手</span>
                            <span class="mt-20 fc-sub fs-14 w-880">深度优化人岗匹配，通过对话进行信息收集，提高招聘全流程的效率和准确性，助力企业从招聘开始快速实现人才梯队基础建设，抢先同行招揽高匹配的人才。</span>
                            <div class="flex-row mt-30">                            
                                <div class="flex-col">
                                    <div class="flex-row items-center">
                                        <span class="flex-row items-center fc-primary fs-44">80 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                        <span class="fs-16-fat fc-main ml-10 mt-10">人岗位匹配率</span>
                                    </div>
                                    <div class="flex-row items-center mt-40">
                                        <span class="flex-row items-center fc-primary fs-44">90 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                        <span class="fs-16-fat fc-main ml-10 mt-10">简历筛选效率提升</span>
                                    </div>
                                    <div class="flex-row items-center mt-40">
                                        <span class="flex-row items-center fc-primary fs-44">50 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                        <span class="fs-16-fat fc-main ml-10 mt-10">面试效率提升</span>
                                    </div>
                                </div>
                                <div class="flex-col ml-80">
                                    <div class="flex-row items-center">
                                        <span class="fs-28 fc-primary">01</span>
                                        <span class="bg-primary ml-20">筛选简历</span>
                                        <span class="fc-sub fs-14 ml-20">智能筛选海量简历，识别分析与推荐优质候选人</span>
                                    </div>
                                    <div class="flex-row items-center mt-20">
                                        <span class="fs-28 fc-primary">02</span>
                                        <span class="bg-primary ml-20">招聘助手</span>
                                        <span class="fc-sub fs-14 ml-20">智能优化企业招聘需求，支持按需匹配同类企业同岗位求职人才</span>
                                    </div>
                                    <div class="flex-row items-center mt-20">
                                        <span class="fs-28 fc-primary">03</span>
                                        <span class="bg-primary ml-20">简历优化</span>
                                        <span class="fc-sub fs-14 ml-20">智能约面与面试流程指引</span>
                                    </div>
                                    <div class="flex-row items-center mt-20">
                                        <span class="fs-28 fc-primary">04</span>
                                        <span class="bg-primary ml-20">求职问答</span>
                                        <span class="fc-sub fs-14 ml-20">行业场景下的专业级面试题/笔试问答</span>
                                    </div>
                                    <div class="flex-row items-center mt-20">
                                        <span class="fs-28 fc-primary">05</span>
                                        <span class="bg-primary ml-20">辅助面试</span>
                                        <span class="fc-sub fs-14 ml-20">强大的专有能力项体系知识辅导</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container flex-col p-r mt-80">
                <img :src="$IMG_PUBLIC_PATH + 'website/case-bg-2%402x.png'" class="case-applet-bg">
                <div class="w-full flex-col items-center">
                    <span class="fs-36 fc-main">AI应用产品</span>
                    <span class="fc-sub fs-20-thin mt-20">红熊AI聚焦行业核心场景，深入打造行业标准化解决方案</span>
                </div>
                <div class="flex-row justify-between mt-40">
                    <div class="flex-col applet-left-box">
                        <span class="flex-row items-center left-menu-items" :class="appletCurItem === i ? 'active' : ''" v-for="(item,i) in appletMenuList" :key="i" @click="appletCurItem = i">{{item.title}}</span>
                        <img :src="$IMG_PUBLIC_PATH + 'website/applet-bg%402x.png'" class="left-img" />
                    </div>
                    <!-- 智能客服 -->
                    <div class="flex-col applet-box text-left" v-if="appletCurItem === 0">
                        <span class="fs-24-fat"><span class="fc-primary">红熊灵启</span>- AI智能工作流构建智能客服</span>
                        <span class="fs-14 fc-sub w-800 mt-20">大模型客服相比传统机器人客服，展现出了更强的语义理解能力和更流畅的对话处理能力，能够提供个性化服务，快速响应用户需求，降低运营成本，提高回答的准确率。</span>
                        <div class="flex-row mt-30">
                            <div class="flex-col text-left">
                                <span class="flex-row items-center fc-primary fs-44">25 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                <span class="mt-10 fs-16-fat fc-main">客服效率提升</span>
                                <span class="mt-100 fs-18-fat fc-main">· 多模态大模型理解</span>
                                <span class="mt-20 fs-18-fat fc-main">· 私有化客服知识库</span>
                                <span class="mt-20 fs-18-fat fc-main">· 强化学习训练</span>
                            </div>
                            <div class="flex-col ml-70 justify-center items-center applet-c-box">
                                <img :src="$IMG_PUBLIC_PATH + 'website/icon-wf-1%402x.png'" class="icon-40" />
                                <span class="flex-row items-center fc-primary fs-44 mt-50">25 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                <span class="mt-60 fs-20-fat fc-primary">准确性提升</span>
                            </div>
                            <div class="flex-col ml-40 justify-center items-center applet-c-box">
                                <img :src="$IMG_PUBLIC_PATH + 'website/icon-wf-2%402x.png'" class="icon-40" />
                                <span class="flex-row items-center fc-primary fs-44 mt-50">85 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                <span class="mt-60 fs-20-fat fc-primary">完整性提升</span>
                            </div>
                            <div class="flex-col ml-40 justify-center items-center applet-c-box">
                                <img :src="$IMG_PUBLIC_PATH + 'website/icon-wf-3%402x.png'" class="icon-40 mt-20" />
                                <span class="flex-row items-center fc-primary fs-44 mt-50">90 <span class="fs-20-fat mt-10 ml-10">+</span></span>
                                <span class="mt-60 fs-20-fat fc-primary text-center">实用性全链路 评分</span>
                            </div>
                        </div>
                    </div>
                    <!-- 电商运营 -->
                    <div class="flex-col applet-box text-left" v-if="appletCurItem === 1">
                        <span class="fs-24-fat">多模态助力<span class="fc-primary">电商运营</span></span>
                        <span class="fs-14 fc-sub w-800 mt-20">以其卓越的数据处理能力，为电商运营提供了全方位支持，精准分析用户需求，通过智能推荐、情感交流等功能，实现与用户的深度互动，满足用户多样化需求，助力电商运营更上一层楼</span>
                        <div class="flex-row w-full justify-between mt-40">
                            <div class="flex-col text-left">
                                <img :src="$IMG_PUBLIC_PATH + 'website/litu-1%402x.png'" class="img-250" />
                                <span class="mt-15 fc-primary fs-16-fat">文生图助力电商营销</span>
                                <span class="mt-15 fc-sub fs-14 w-250">通过精准用户画像生成图片，提升电商营销效果，转化率提升30%，红熊与商家合作实现双赢，业绩增长20%以上。</span>
                            </div>
                            <div class="flex-col text-left">
                                <img :src="$IMG_PUBLIC_PATH + 'website/litu-2%402x.png'" class="img-250" />
                                <span class="mt-15 fc-primary fs-16-fat">电商运营智能化提升效率</span>
                                <span class="mt-15 fc-sub fs-14 w-250">引入大模型文本生成图片技术，电商运营效率提升50%，人工成本降低40%。</span>
                            </div>
                            <div class="flex-col text-left">
                                <img :src="$IMG_PUBLIC_PATH + 'website/litu-3%402x.png'" class="img-250" />
                                <span class="mt-15 fc-primary fs-16-fat">大模型文生图构建智慧零售新生态</span>
                                <span class="mt-15 fc-sub fs-14 w-250">大模型文生图助力智慧零售，日升消费者体验，销售额同比增长15%，让消费转化，进一步提升。</span>
                            </div>
                        </div>
                    </div>
                    <!-- 公益 -->
                    <div class="flex-col applet-box text-left" v-if="appletCurItem === 2">
                        <span class="fs-24-fat"><span class="fc-primary">红熊&公益</span>-公益漫画</span>
                        <span class="fs-14 fc-sub w-800 mt-20">通过智能算法捕捉情感细微变化，以生动的笔触讲述温情故事，唤起社会共鸣。让爱与关怀在漫画中流淌，温暖每一个人的心灵。</span>
                        <div class="flex-row justify-between mt-40">
                            <div class="flex-col">
                                <span class="fs-16-fat fc-primary">01 科技助力公益</span>
                                <span class="mt-15 fc-sub w-380 fs-14">通过分析海量数据，为公益机构提供精准的漫画素材，提高公益活动的针对性和效率，让年轻人更喜欢公益。</span>
                                <span class="fs-16-fat fc-primary mt-30">03 科技助力环保</span>
                                <span class="mt-15 fc-sub w-380 fs-14">通过生成式AI，创建画作减少纸张浪费，为环保提供支持，减少环境污染，保护地球家园。</span>
                            </div>
                            <div class="flex-col">
                                <span class="fs-16-fat fc-primary">02 红熊&公益漫画传播正能量</span>
                                <span class="mt-15 fc-sub w-380 fs-14">以生动有趣的方式传递公益理念，成为公益宣传的新宠。</span>
                                <span class="fs-16-fat fc-primary mt-50">04 公益漫画影响广泛</span>
                                <span class="mt-15 fc-sub w-380 fs-14">红熊&公益漫画在互联网上曝光量正在提升，有效提高了公众对公益事业的关注度和参与度。</span>
                            </div>
                        </div>
                        <div class="flex-row justify-between mt-40">
                            <img :src="$IMG_PUBLIC_PATH + 'website/litu-5%402x.png'" class="img-gy" />
                            <img :src="$IMG_PUBLIC_PATH + 'website/litu-6%402x.png'" class="img-gy" />
                            <img :src="$IMG_PUBLIC_PATH + 'website/litu-7%402x.png'" class="img-gy" />
                        </div>
                    </div>
                    <!-- 小红书 -->
                    <div class="flex-col applet-box text-left" v-if="appletCurItem === 3">
                        <span class="fs-24-fat"><span class="fc-primary">红熊&小红书</span>-私人影像</span>
                        <span class="fs-14 fc-sub w-800 mt-20">精准捕捉用户需求，定制专属影像风格。不仅满足审美，更传递情感温度，让每一天的影像记录都充满温情与回忆，助力用户留下独一无二的珍贵瞬间。</span>
                        <div class="mt-40 flex-row justify-between">
                            <div class="flex-col mt-130">
                                <span class="fc-primary fs-16-fat">01 大模型文生图科技助力影像创新</span>
                                <span class="mt-15 w-468">随着大模型文生图技术的不断进步，红熊与小红书达人合作的私人影像项目展现了其在智能化影像生成方面的卓越能力，极大地丰富了用户的个性化影响体现，丰富的素材，让达人随时可以创作和分享生活日常。</span>
                                <span class="fc-primary mt-60 fs-16-fat">02 私人影像需求持续增长</span>
                                <span class="mt-15 w-468">据统计，小红书上的私人影像内容发布量年增长率达30%，表明用户对于个性化、创意化影像的需求正不断增长。</span>
                            </div>
                            <img :src="$IMG_PUBLIC_PATH + 'website/litu-4%402x.png'" class="img-xhs" />
                        </div>
                    </div>
                    <!-- 智能助手 -->
                    <div class="flex-col applet-box text-left" v-if="appletCurItem === 4">
                        <span class="fs-24-fat"><span class="fc-primary">AI 智能对话助手 </span>构建健康守护营养师</span>
                        <span class="fs-14 fc-sub w-800 mt-20">AI 智能对话提供营养相关专业指导和健康饮食建议，帮助越来越多的消费者及家庭，随时随地进行专业饮食指导</span>
                        <div class="flex-row w-full mt-60 p-r justify-between">
                            <div class="flex-col text-left">
                                <span class="flex-row items-center fc-primary fs-44">10 <span class="fs-20-fat mt-10 ml-10">%</span></span>
                                <span class="mt-10 fs-16-fat fc-main">整体员工效率提升</span>
                                <span class="mt-130 fs-18-fat fc-main">· 健康评测</span>
                                <span class="mt-20 fs-18-fat fc-main">· 营养健康报告</span>
                                <span class="mt-20 fs-18-fat fc-main">· 报告内容生成</span>
                            </div>
                            <div class="flex-col ai-box-all">
                                <div class="flex-row ai-box justify-between items-center">
                                    <div class="flex-col text-left">
                                        <span class="fc-primary fs-16-fat">智能健康营养专家</span>
                                        <span class="mt-20 fc-sub fs-14">专业知识解答/个性化营养搭配建议/随时随地的饮食指导</span>
                                    </div>
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-applet-1%402x.png'" class="icon-40" />
                                </div>
                                <div class="flex-row ai-box ai-box-1 justify-between items-center mt-30">
                                    <div class="flex-col text-left">
                                        <span class="fc-primary fs-16-fat">用户营养健康评测</span>
                                        <span class="mt-20 fc-sub fs-14">缺钙风险评估/免疫力状况评估/营养均衡测试分析</span>
                                    </div>
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-applet-2%402x.png'" class="icon-40" />
                                </div>
                                <div class="flex-row ai-box ai-box-2 justify-between items-center mt-30">
                                    <div class="flex-col text-left">
                                        <span class="fc-primary fs-16-fat">营养计划制定及监督</span>
                                        <span class="mt-20 fc-sub fs-14">营养配餐/智能共情陪伴/智能提醒/健康调理计划管理/瘦身计划、肠健康养护等</span>
                                    </div>
                                    <img :src="$IMG_PUBLIC_PATH + 'website/icon-applet-1%402x.png'" class="icon-40" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <regbox-footer/>
        <airb-footer class="mt-60"/>
        <!-- <redbear-chat-window></redbear-chat-window> -->
    </div>
</template>
<script>
import headerNav from '@/components/nav.vue'
import airbFooter from '@/components/footer.vue'
import regboxFooter from '@/components/footer-reg.vue'
const solutionTablist = [
    {
        title:'B2B电商',
        templateName: '1',
        content:''
    },
    {
        title:'咨询',
        templateName: '2',
        content:''
    },
    {
        title:'传媒',
        templateName: '3',
        content:''
    },
    {
        title:'旅游',
        templateName: '4',
        content:''
    },
    {
        title:'心理咨询',
        templateName: '5',
        content:''
    },
    {
        title:'汽车',
        templateName: '6',
        content:''
    },
    {
        title:'企业招聘',
        templateName: '7',
        content:''
    }
] 
const appletMenuList = [
    {id:1,title:'智能客服'},{id:2,title:'电商运营'},{id:3,title:'公益'},{id:4,title:'小红书'},{id:5,title:'智能助手'},
]
export default {
    components:{
        headerNav,airbFooter,regboxFooter
    },
    data(){
        return{
            solutionTablist,
            solutionCurItem:0,
            appletMenuList,
            appletCurItem:0
        }
    },
    mounted(){
        
    }
}
</script>
